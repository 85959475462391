import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigAssetLoaderService } from '@core/services/config-asset-loader.service';
import { MandantClient } from '@shared/models/mandantenClient';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    mandantenUIApiUrl: string;

    constructor(private http: HttpClient, private configService: ConfigAssetLoaderService) {
        this.mandantenUIApiUrl = this.configService.getConfig().k5Mandanten.mandantenServiceBaseUrlUIApi;
    }

    /**
     * Gibt die aktuellen Benutzereinstellungen zurück
     */
    getUserSettings(benutzerId: string): Observable<MandantClient.UIBenutzerEinstellungen> {
        return this.http.get<MandantClient.UIBenutzerEinstellungen>(
            `${this.mandantenUIApiUrl}/Benutzer/${benutzerId}/Einstellungen`
        );
    }

    /**
     * Updated die Benutzereinstellungen
     */
    updateUserSettings(
        data: MandantClient.UIBenutzerEinstellungenData,
        benutzerId: string
    ): Observable<MandantClient.UIBenutzerEinstellungen> {
        return this.http.put<MandantClient.UIBenutzerEinstellungen>(
            `${this.mandantenUIApiUrl}/Benutzer/${benutzerId}/Einstellungen`,
            data
        );
    }
}
